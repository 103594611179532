
          @import "src/sass/_variables.scss";
        
.ResellerApplicationWindow {
	
	.modal-content-container {
		.Form {
			.group {
				
				.row.top {
					max-width: 100%;
					flex-basis: 100%;
					
					> div {
						padding: 0;
						text-align: left;
						max-width: 100%;
						flex-basis: 100%;
					}
				}
				.row.top:first-child {
					margin-right: 1.25rem;
				}
			}
			
			> .container {
				
				.description-text {
					margin-bottom: 2.1875rem;
				}
				
				.first {
					padding-right: 1rem;
				}
				
				.button {
					margin-top: 2rem;
				}
				
				.complete {
					padding: 2rem 3rem 0;
				}
				
				.input-container {
					flex-basis: 100%;
					max-width: 100%;
				}
				.complete {
					margin: 40px
				}

				
			}
			.Button {
				margin-top: 1.25rem;
			}
			
			.checkboxes {
				.Checkbox {
					margin-bottom: 0.9375rem;
				}
			}
		}
	}
}