
          @import "src/sass/_variables.scss";
        
.SearchInput {
	position: relative;
	width: 100%;
	margin: 0.625rem;
	max-width: 50rem;
	
	.close-button {
		all: unset;
		background-color: var(--gd-dark-prime);
		border-radius: 6.25rem;
		height: 1.5625rem;
		width: 1.5625rem;
		color: #fff;
		position: absolute;
		right: -0.7188rem;
		top: -0.7188rem;
		display: none;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	&.have-search-query {
		.close-button {
			display: flex;
		}

		.text-input {
			outline: 0.125rem solid var(--gd-dark-prime);
			outline-offset: -0.125rem;
		}
	}

	.search-button {
		position: absolute;
		left: 0;
		top: 0;
		height: 3.125rem;
		width: 3.75rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	
	.text-input {
		border-radius: 0.625rem;
		border: unset;
		outline: 0.0625rem solid var(--gd-border);
		outline-offset: -0.0625rem;
		width: 100%;
		height: 3.125rem;
		max-width: 50rem;
		box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.05);
		padding-left: 3.75rem;
	}
}
