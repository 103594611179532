
          @import "src/sass/_variables.scss";
        
.WebText {
	&.preview {
		z-index: 1;
		position: relative;

		&.translation-fallback {
			&::before {
				border: 0.0625rem solid #1c1c73;
				background-color: #bcc5ff;
			}
		}

		&.html-text {
			&::before {
				border: 0.0625rem solid #195f10;
				background-color: #bcffc5;
			}
		}

		&::before {
			content: '';
			background-color: #ffb3b3;
			border: 1px solid #700404;
			display: block;
			height: calc(100% + 0.25rem);
			width: calc(100% + 0.625rem);
			position: absolute;
			left: -0.3125rem;
			top: -0.125rem;
			border-radius: 0.1875rem;
			z-index: -1;
		}
	}

	label {
		// Same as MainLabel (should be)
		font-family: var(--gd-header-font-family, sans-serif);
		display: block;
		font-size: 1rem;
		line-height: 1.1875rem;
		font-weight: 500;
		font-style: normal;
		margin-bottom: 1rem;
		margin-top: 0.9375rem;
		color: var(--gd-main-text-color);
	}

	.edit-button {
		width: 2rem;
		height: 2rem;
	}

	img {
		max-width: 100%;
		border: 1px solid var(--gd-border);
		border-radius: 0.3125rem;
	}
}
