
          @import "src/sass/_variables.scss";
        
.IconValueRow {
	display: flex;
	align-items: center;
	margin-bottom: 0.9375rem;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	span {
		margin-right: .9375rem;
		overflow-wrap: anywhere;
	}

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 1.0625rem;
	}
}