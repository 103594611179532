
          @import "src/sass/_variables.scss";
        
.ContactInformationModal {
	
	> p, > .IconLink {

		margin-bottom: 0.625rem;
	}

	.support-contact,
	.sales-section {
		min-width: 15.625rem;
	}
}