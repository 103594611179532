.latakko {
	--gd-detail-color: #003365;
	--gd-detail-gradient: linear-gradient(90.49deg, #003365 0%, #003365 100%);
	@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Marcellus:wght@400&family=Roboto:wght@300;400;500;700;800&display=swap');
	
	.Header {
		.logo {
			margin-left: 1.5rem;
			display: flex;
			align-items: center;
			
			.logo-img {
				width: auto;
				height: 3.75rem;
			}
		}
	}
	
	.CartListView .cart-row .col-5 {
		max-width: 11.25rem;
	}
	
	@media screen and (max-width: $screen-lg-max) {
		.MiniCart {
			margin: 0;
			margin-right: 0.3125rem;
		}
	}
}