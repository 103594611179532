.simple-message {
	width: 100%;
	background-color: var(--gd-color-message, #72BAE4);
	box-shadow: inset 0 0 0 0.0625rem rgba(0, 0, 0, 0.2), var(--gd-box-shadow-overlay, 0 0.25rem 2.5rem rgba(0, 0, 0, 0.1));
	border-radius: 0.3125rem;
	padding: 0.9375rem;
	margin-top: .625rem;
	font-size: 0.8125rem;
	display: flex;
	flex-direction: row;
	align-items: center;

	&.error {
		background-color: var(--gd-color-error, #EE8686);
		color: var(--gd-text-color-error, #B52B2B);
	}

	&.success {
		background-color: var(--gd-color-success, #C2E3A0);
		color: var(--gd-text-color-success, #7AA74E);
	}

	&.warning {
		background-color: var(--gd-color-warning, #F4D59E);
		color: var(--gd-text-color-warning, #BE944B);
	}

	&.spaced {
		margin-bottom: 1.25rem;
	}
}