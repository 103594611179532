
          @import "src/sass/_variables.scss";
        
.LanguageSelector {
	height: 1.875rem;

	.ListViewSelectPicker {
		padding-right: 0;

		.picker-container {
			min-width: unset;
			max-width: unset;
			
			.value {
				width: 100%;
				padding-right: 2.1875rem;
				margin-left: 0.625rem;
			}
		}
	}
	
	@media screen and (max-width: $screen-md-max) {
		height: 3.75rem;
		margin: 0;
	}
}