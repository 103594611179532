
          @import "src/sass/_variables.scss";
        
.QuantityInput {
	display: flex;
	justify-content: right;
	align-items: center;
	height: 100%;
	
	.picker-container {
		width: 3.4375rem;
		min-width: unset;
	}

	.ListViewSelectPicker {
		padding-right: 0;
	}

	.ListViewQuantityInput {
		.input {
			font-family: var(--gd-font-condensed);
			width: 3.4375rem;
			padding-left: 0;
			text-align: center;
			padding-right: 1.25rem;
		}
	}
}
