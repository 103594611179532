
          @import "src/sass/_variables.scss";
        
.ContentEditButton {
  position: relative;
  z-index: 30000;

  .edit-button {
    display: flex;
    position: absolute;
    z-index: 30000;
    top: -1rem;
    right: -3rem;
    border: 1px solid black;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    background-color: rgba(245, 245, 220, 0.6);
    cursor: pointer;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}