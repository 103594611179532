
          @import "src/sass/_variables.scss";
        
.Footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 1.25rem;
	margin: 0 0 0 1.25rem;

	&.alignLeft {
		align-items: start;
	}

  .footer-wrapper {
    max-width: 90rem;
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
  }
	
	small {
		margin: 3.125rem 0;
	}

	.text-spacer {
		margin: 0 0.3125rem;
	}

	.language-list {
		margin-left: 2rem;
	}

	@media screen and (max-width: $screen-md-max) {
		margin: 0 1.25rem;
	}
}