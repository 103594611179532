
          @import "src/sass/_variables.scss";
        
.CategoryMenu {
  padding-top: 2.5rem;
	user-select: none;
	-webkit-tap-highlight-color: transparent;

  .background-cover {
    position: relative;
    display: flex;
    height: 7.5rem;
		left: 0;
		width: fit-content;
    border-radius: 0.625rem 0.625rem 0 0;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;

    .item-active-cover {
      position: absolute;
      border-radius: 0.625rem 0.625rem 0 0;
      left: 0;
      bottom: 0;
      width: 9.375rem;
      height: 8.125rem;
      background-color: var(--gd-primary-color);
      transition: 400ms ease;
    }
  }

	.mobile-menu {
		display: none;
	}
}

@media screen and (max-width: $screen-md-max) {
	.CategoryMenu {
		padding-top: 0;

		.background-cover {
			display: none;
		}

		.mobile-menu {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 1.875rem;

			.SelectPicker {
				width: 100%;
				max-width: unset;

				.picker-container {
					max-width: unset;
				}
			}
		}
	}
}