
          @import "src/sass/_variables.scss";
        
.Logo {
	.logoText {
		display: flex;
		justify-content: center;
		font-size: 1.25rem;		
		margin-top: 2rem;
	}
}