
          @import "src/sass/_variables.scss";
        
.UserMenu {
	font-size: 0.8125rem;
	height: 2.1875rem;
	user-select: none;
	pointer-events: none;
	z-index: 100;

	.account-item {
		all: unset;
		height: 2.1875rem;
		min-width: 12.5rem;
		box-sizing: border-box;
		padding: 0 0.625rem 0 1.25rem;
		background-color: var(--gd-color-gray);
		border-left: 0.0625rem solid var(--gd-color-gray-70);
		border-right: 0.0625rem solid var(--gd-color-gray-70);
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		pointer-events: all;
	
		&:hover {
			background-color: var(--gd-color-gray-90);
		}

		span:first-child {
			margin-right: 0.625rem;
		}
		
		.user-icon {
			width: 1.25rem;
			height: 1.25rem;
			color: var(--gd-primary-color-detail)
		}
	}

	.account-menu {
		background-color: var(--gd-color-gray);
		border-radius: 0 0 0.125rem 0.125rem;
		padding: 0.625rem 0;
		box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
		width: 100%;
    max-width: 12.5rem;
    position: absolute;
    right: 0;
    top: 100%;
		pointer-events: all;

		.menu-items {
			height: 2.1875rem;
			padding: 0 0.625rem 0 1.25rem;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&:hover {
				background-color: var(--gd-color-gray-90);
			}

			
			.notification-badge {
				background-color: var(--gd-primary-color-detail);
				min-width: 1.25rem;
				height: 1.25rem;
				padding: 0 0.3125rem;
				border-radius: 1.25rem;
				font-size: 0.75rem;
				color: var(--gd-primary-color);
				display: block;
				text-align: center;
				line-height: 1.25rem;
			}
		}

		.logedin-as-item {
			line-height: 1rem;
			display: block;
			height: 2.8125rem;
			padding: 0 0.625rem 0 1.25rem;

			.logedin-as {
				color: #CCCCCC;
				font-weight: 700;
				font-size: 0.625rem;
				line-height: 1.4375rem;
			}
		}
	}

}

