
          @import "src/sass/_variables.scss";
        
.RetailPriceSwitch {
	display: inline-flex;
	
	.excl-vat {
		&.alone {
			margin-left: 0;
		}
	}

	.list-settings {
		display: flex;
		align-items: center;
	}

	.information {
		text-align: center;
	}
}