
          @import "src/sass/_variables.scss";
        
.DesktopHeader {
	display: flex;
	justify-content: center;
	position: relative;
	background: #fff;
	
	&.search-open {
		z-index: 1000;
	}
	
	.header-wrapper {
		width: 100%;
		max-width: 106.25rem;
		display: flex;
		align-items: center;

		.Logo {
			height: 5.625rem;
			margin-right: 1.25rem;

			img {
				height: 5.625rem;
				width: auto;
			} 
		}

		.link-panel {
			display: flex;
			margin-left: auto;

			.SearchButton, 
			.QuickSettings,
			.LanguageSelector {
				margin-right: 1.25rem;
			}
		}
		
		.end-bar {
			display: flex;
			justify-content: center;
			align-items: center;
			
			.line-spacer {
				height: 4.375rem;
				border-left: 0.0625rem solid var(--gd-border);
			}
			
			.or-text {
				margin-left: 1.25rem;
				text-transform: lowercase;
				color: var(--gd-disabled)
			}

			&.login {
				.becomecustomer-button {
					margin-left: 1.25rem;
					cursor: pointer;
				}

				.login-button {
					margin-left: 1.25rem;
					min-width: 10.9375rem;
				}

				.LanguageSelector {
					margin-right: 1.25rem;
				}
			}
		}

	}

	.text-spacer {
    margin: 0 1.25rem;
    font-size: 0.8125rem;
	}
}