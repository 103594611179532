h1,
h2,
h3,
h4 {
	margin-top: 0;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0.01em;
	font-family: var(--gd-header-font-family, sans-serif);
	color: var(--gd-main-text-color);
}

h1 {
	font-size: 2.1875rem;
	line-height: 2.5625rem;
}

h2 {
	font-size: 1.5625rem;
	line-height: 1.8125rem;
	margin-bottom: 1.3125rem;
}

h3 {
	font-size: 1.25rem;
	line-height: 1.4375rem;
}

h4 {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.1875rem;
}

p {
	font-weight: 400;
	font-size: 1rem;
	margin-top: 0;
  margin-bottom: 0.9375rem;
	line-height: 1.25rem;
	font-family: var(--gd-header-font-family, sans-serif);
	color: var(--gd-main-text-color);
}

a {
	color: var(--gd-primary);
	text-decoration: none;
}

label {
	// Use components MainLabel, InputLabel and so on instead
	color: var(--gd-main-text-color);
}

hr {
	border-top: 0.0625rem solid var(--gd-border);
}

ul {
	font-family: var(--gd-header-font-family, sans-serif);
	padding-left: 1.5625rem;
	color: var(--gd-main-text-color);

	li {
		font-size: 1rem;
		line-height: 1.25rem;
		color: var(--gd-main-text-color);
	}
}
