
          @import "src/sass/_variables.scss";
        
.MobileHeader {
	height: 7.5rem;
	
	.header-wrapper {
		height: 3.75rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	
		.menu-button {
			padding: 0.9375rem;
			color: var(--gd-main-text-color);
		}

		.Logo {
			height: 3.75rem;
			margin-left: 0;

			.logo-img {
				width: unset;
				height: 3.75rem;
			}
		}

		.center-logo {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
		}
	}
	
	.login-wrapper {
		margin-right: auto;

		.login-button {
			all: unset;	
			cursor: pointer;
		}
	}

	.header-sub-wrapper {
		display: flex;
		padding: 0 0.9375rem 0;
		background-color: var(--gd-sub-white);
		
		> div {
			display: flex;
		}

		.my-pages-wrapper {
			display: flex;
			align-items: center;
			margin-right: auto;

			a {
				display: flex;
				color: var(--gd-main-text-color);
				text-decoration: none;
				
				.my-pages-icon {
					color: var(--gd-main-text-color);
					margin-right: 0.625rem;
				}

				.my-pages-label {
					line-height: 1.875rem;
				}
			}
		}
	}
}