
          @import "src/sass/_variables.scss";
        
.SocialLinks {
	display: flex;
	gap: 1.25rem;
	height: 1.5rem;
	margin-top: 1.25rem;
	
	a {
		color: var(--gd-main-text-color);
		transition: 0.2s ease;

		&:hover {
			color: var(--gd-primary-color-dark-hover, #3C3C3C);
		}
	}
}