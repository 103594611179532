
          @import "src/sass/_variables.scss";
        
.SidebarLayout {
	.content-wrapper {
		&.overlapping-content {
			.layout-content {
				width: 100%;
				padding-top: 2.1875rem;
				
				.content-row {
					position: relative;
					display: flex;
					margin: auto;
					width: 100%;
					max-width: 106.25rem;
					
					section.content-full {
						position: absolute;
						left: 15rem;
						max-width: calc(100% - 15rem);
						width: 100%;
						border-radius: 0 0.625rem 0 0;
						background: #ffffff;
					}

					@media only screen and (max-width: $screen-md-max) { 
						section.content-full {
							left: 0;
							max-width: 100%;
						}
					}
				}
			}
			@media only screen and (max-width: $screen-md-max) { 
				.layout-content {
					padding-top: unset;
				}
			}
		}

		.background-wrapper {
			position: absolute;
			overflow: hidden;
			width: 100%;
			left: 0;
			top: 0;
			z-index: -1;
			

			.detail-background {
				height: 10rem;
				width: 100vw;
			}

			.no-background {
				height: 100%;
				width: 100%
			}
		}
	}
	
	/** Sidebar */
	.Sidebar {
		border-radius: 0.625rem 0 0 0;
		padding-top: 1.25rem;
		min-height: calc(100vh - 7.5rem - 10rem - 2.1875rem);
	}

	.no-categories {
		.Sidebar {
			min-height: calc(100vh - 7.5rem - 2.1875rem);
		}
	}

	@media only screen and (max-width: $screen-lg-max) {
		.content-wrapper {
			.content-full {
				&.full-screen {
					.search-form-wrapper {
						margin-left: auto;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1275px) {
		.content-wrapper {
			&.overlapping-content {
				.Sidebar {
					border-radius: 0
				}
				.layout-content .content-row section.content-full {
					border-radius: 0
				}
			}
		}
	}

	@media only screen and (max-width: $screen-md-max) {
		.content-wrapper {
			.no-background {
				min-height: calc(100vh - 14.375rem);
				
				&.overlapping-content {
					.layout-content {
						padding-top: 0;
					}
				}
			}
		}
	}
}