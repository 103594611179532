
          @import "src/sass/_variables.scss";
        
.LayoutControls {
	display: inline-flex;
	align-items: center;
	
	&.standalone {		
		background-color: var(--gd-wrapper-gray);
		padding: 1.25rem;
		border-radius: 10px;

		.screen-mode-type {
			margin-right: 0;
		}
	}
	
	.screen-mode-type {
		height: 1.25rem;
		margin-right: 1.25rem;
	}

	.view-button {
		all: unset;
		cursor: pointer;
		height: 1.25rem;

		svg > path {
			fill: var(--gd-main-text-color);
		}
		
		&.active {
			svg > path {
				fill: var(--gd-disabled);
			}
			cursor: unset;
		}

		&:first-child {
			margin-right: 0.9375rem;
		}
	}
}