
          @import "src/sass/_variables.scss";
        
.QuickSettings {
	display: flex;
	justify-content: center;
	align-items: center;
	
	.icon-wrapper {
		all: unset;
		cursor: pointer;
		display: flex;
		width: 1.875rem;
		height: 1.875rem;
		justify-content: center;
		align-items: center;
	}

	.modal-content-container {
		width: 100%;
		max-width: 31.25rem;
		
		.RetailPriceSwitch {
			display: inline-block;
		}
		
		.CMS-settings {
			margin-top: 1.25rem;
			
			.CMSPreviewSwitch {
				margin-top: 1.25rem;
			}
		}
	}


	@media screen and (max-width: $screen-md-max) {
		width: 3.75rem;
		height: 3.75rem;
		margin: 0 0 0 -1.25rem;
		
		> div {
			height: 1.875rem;
		}

		svg {
			width: 1.875rem;
			height: 1.875rem;
		}
	}

	@media screen and (max-width: $screen-lg-max) {
		margin-right: 0;
	}
}