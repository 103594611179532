
          @import "src/sass/_variables.scss";
        
.Price {
	&.always-retail-price,
	&.retail-price {
		// Only change colors if retail-priceis on
		&.excl-vat-retail {
			color: var(--gd-excl-vat);
		}
	}

	&.incl-excl-vat-enabled {
		// Only change colors if price in vat can be shown
		&.excl-vat {
			color: var(--gd-excl-vat);
		}
	}

	.vat-text {
		font-size: 0.8125rem;
		color: var(--gd-main-text-color)
	}
}