
          @import "src/sass/_variables.scss";
        
.MaintenancePage {
	width: 100%;


  .space-placer {
    display: flex;
    justify-content: flex;
    align-items: center;

    .text-wrapper {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-left: 3.125rem;
      max-width: 35rem;
      min-height: 26.25rem;
			
			p {
				margin-bottom: 3rem;
			}
    }
  }
	
  .hero {
    height: 100%;
    max-height: 70vh;
    overflow: hidden;
    padding: 2.8125rem 0;

    .main-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
	
	@media (max-width: $screen-xxs-max) {
		h1 {
			font-size: 2.5rem;
		}

		.space-placer {
			.text-wrapper {
				padding: 1.25rem;
			}
		}	
	}
}