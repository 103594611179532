
          @import "src/sass/_variables.scss";
        
.PasswordResetForm {
	.infoText {
		padding-bottom: 1.5rem;
	}
	.pw-reset-btn {
		padding-top: 1.5rem;
	}
}