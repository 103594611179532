
          @import "src/sass/_variables.scss";
        
.SearchButton {
	all: unset;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
  width: 1.875rem;
	height: 1.875rem;
	
	svg {
		color: var(--gd-main-text-color);
	}
}
