.brackevulkverkstadab {
	--gd-detail-color: #999999;
	--gd-detail-gradient: #9b9a9a;
	--gd-cat-menu-color: var(--gd-cat-menu-color-active);

	.CategoryMenu {
		.background-cover {
			background: rgba(255, 255, 255, 0.2);
		}
	}
}