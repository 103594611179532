
          @import "src/sass/_variables.scss";
        
.LayoutWrapper {
	position: relative;
	display: flex;
	flex-direction: column;

	.dark-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--gd-color-gray-40);
		opacity: 0.3;
		z-index: 1;
	}
	
	.hidden {
		opacity: 0;
		pointer-events: none;
		width: 0;
		height: 0;
	}

	.content {
		z-index: 0;
	}
}
