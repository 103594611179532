
          @import "src/sass/_variables.scss";
        
.ArticleLinkWindow {
  .buttons {
    margin-top: 2rem;
		.Button {
			margin: 0 .5rem;
		}
  }

	.Modal {
    .modal-content-container {
      width: 45rem;
      max-width: 95vw;
    }
  }
}