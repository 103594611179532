
          @import "src/sass/_variables.scss";
        
.CreditInfo {
  position: relative;
  user-select: none;
  cursor: pointer;

  .credit-wrapper {
    background: var(--gd-color-gray, #F8F8F8);
    border-radius: 0.625rem;
    padding: 0.625rem;
    font-size: 0.8125rem;
    line-height: 1.5625rem;
    text-align: center;
		margin-right: 1.25rem;
  }

  .SpeachBubble {
    p {
      font-size: 0.8125rem;
      font-weight: 400;
			max-width: 10rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
    }
  }
	.credit-svg {
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 1.5rem;
			height: 1.5rem;
			fill: var(--gd-color-gray-60, #999999)
		}
	}

}

.creditInfo-mobile-view {
	padding: 1.25rem;
	margin: 1.25rem;
	background-color: #eee;
	border-radius: 0.625rem;
	
	.text-row {
		display: flex;
		justify-content: space-between;
		
		p {
			line-height: 0.75rem;
			font-size: 0.75rem;
		}
	}
}