
          @import "src/sass/_variables.scss";
        

.SubMenu {
	width: 100%;
	height: 2.1875rem;
	position: relative;

	&.notLoggedIn {
		background-color: var(--gd-color-gray);
	}

	.SubMenu-wrapper {
		display: flex;
		width: 100%;
		margin: auto;
		max-width: 120rem;
		height: 2.1875rem;
		z-index: 1;
		line-height: 2.1875rem;
		transition: max-width .4s ease;

		&::before {
			content: "";
			position: absolute;
			height: 2.1875rem;
			width: 100%;
			display: block;
			top: 0;
			z-index: 10;
			transform: translate(calc(-100% + 15rem)); // 15rem is the width of the menu
			background-color: var(--gd-color-gray);
		}

		&::after {
			content: "";
			position: absolute;
			height: 2.1875rem;
			width: calc((100% - (120rem - 15rem)) / 2);	// 15rem is the width of the menu
			display: block;
			top: 0;
			right: 0;
			z-index: 10;
			background-color: var(--gd-color-gray);
		}
		
		.menu {
			display: flex;
			flex: 1;
			max-width: calc(100% - 15rem);
			margin-left: auto;

			.filler {
				flex: 1;
				background-color: var(--gd-color-gray);
			}
		}
		
	}

	.right-items {
		font-size: 0.8125rem;
    position: absolute;
    right: 50%;
    top: 0;
    width: 100%;
    transform: translateX(50%);
    max-width: 106.25rem;
    height: 2.1875rem;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: 100;
		display: flex;
		justify-content: flex-end;
	}

	&.full-screen {
		.SubMenu-wrapper {
			max-width: 100%;
		}
	}
}