
          @import "src/sass/_variables.scss";
        
.MiniCart {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5rem;
	height: 5rem;
	margin: 1.25rem;
	cursor: pointer;

	&:hover::before {
		content: "";
		width: calc(100% + 0.625rem);
		height: calc(100% + 0.25rem);
		display: block;
		position: absolute;
		left: -0.3125rem;
		top: -0.125rem;
		border-radius: 0.3125rem;
		background-color: rgba(0, 0, 0, 0.05);
	}

	a {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		width: 3.4375rem;
		height: 3.4375rem;
	}

	.cart-qty {
		position: absolute;
    right: 0.625rem;
    bottom: 0.625rem;
    font-size: 0.75rem;
    color: var(--gd-main-text-color);
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    background: #56C776;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
		padding-right: 0.0625rem;
		padding-top: 0.0625rem;
    color: #fff;
    border-radius: 6.25rem;
    aspect-ratio: 1/1;
    height: 1.25rem;
	}

	svg {
		width: 2.1875rem;
		height: 2.1875rem;
	}

	@media screen and (max-width: $screen-md-max) {
		width: 3.75rem;
		height: 3.75rem;
		margin: 0;

		svg {
			width: 1.875rem;
			height: 1.875rem;
		}
	}
}

.MiniCart-wrapper {
	position: relative;
}