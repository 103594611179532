
          @import "src/sass/_variables.scss";
        
.MainLabel {
	display: block;
	font-size: 1rem;
	line-height: 1.1875rem;
	font-weight: 500;
	font-style: normal;
	margin-bottom: 1rem;
	margin-top: 0.9375rem;
	font-family: "Roboto", sans-serif;
	color: var(--gd-main-text-color);

	&.no-top-margin {
		margin-top: 0;
	}

	&.no-bottom-margin {
		margin-bottom: 0;
	}
}
