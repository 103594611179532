
          @import "src/sass/_variables.scss";
        
.SlimProductCard {
	width: 100%;
	padding: 0.625rem;
	display: flex;
	border-radius: 0.25rem;

	&:hover {
		cursor: pointer;
		background-color: var(--gd-hover);
	}

	.product-image {
		display: block;
		height: 6.25rem;
		width: 6.25rem;
		border-radius: 0.3125rem;
		border: 0.0625rem solid var(--gd-border);
		overflow: hidden;
		background-color: #fff;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	.product-info {
		display: flex;
		flex-direction: column;
		margin-left: 1.25rem;
		justify-content: space-between;
		
		.product-details {
			display: flex;
			
			.Price {
				font-weight: 600;
			}

			.StockQuantity {
				justify-content: left;
				margin-left: 1.25rem;
			}
		}
	}
}