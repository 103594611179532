.racepart {
	--gd-detail-color: #3292F9;
	--gd-detail-gradient: #3292F9;
	//--gd-cat-menu-color: var(--gd-cat-menu-color-active);

	// .CategoryMenu {
	// 	.background-cover {
	// 		background: rgba(255, 255, 255, 0.4);
	// 	}
	// }
}