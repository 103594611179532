
          @import "src/sass/_variables.scss";
        
.LoginSection {
	width: 17.1875rem;

	.password-panel {
		margin: 1.25rem 0;
		display: flex;
		justify-content: space-between;

		.reset-button {
			all: unset;
			cursor: pointer;
			color: var(--gd-primary);
			text-align: center;
			font-size: 0.8125rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.125rem;
			text-decoration-line: underline;
		}
	}

	.message.error {
    min-width: 100%;
		margin-bottom: 0.625rem;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}