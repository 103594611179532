
          @import "src/sass/_variables.scss";
        
.HorizontalSpacer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	color: var(--gd-border);
	padding: 1.0625rem 0rem;
	font-size: 0.8125rem;

	&::before, &::after {
		content: "";
		display: block;
		height: 0.0625rem;
		width: 100%;
		background-color: var(--gd-border);
	}

	&::before {
		margin-right: 1rem;
	}

	&::after {
		margin-left: 1rem;
	}
}