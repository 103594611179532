
          @import "src/sass/_variables.scss";
        
.MobileMainMenu {
	display: block;
	position: fixed;
	height: 100vh;
	width: 100vw;
	right: 0;
	top: 0;
	user-select: none;
	pointer-events: none;
	z-index: 9000;
	transition: transform 0.4s ease;
	
	.mobile-menu-cover {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.2);
		opacity: 0;
		transition: 0.2s ease;
		pointer-events: none;
	}
	 
	.mobile-menu-wrapper {
		box-shadow: 0px 0px 20px 2px rgba(0,0,0,0);
		position: fixed;
		display: block;
		left: -18.75rem;
		top: 0;
		height: 100vh;
		width: 18.75rem;
		background: #fff;
		overflow: hidden;		
		transition: 0.2s ease;
		pointer-events: all;
	}

	&.open {
		.mobile-menu-cover {
			opacity: 1;
			pointer-events: all;
		}
		.mobile-menu-wrapper {
			transform: translateX(100%);
			box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.2);
		}
	}
	
	.sliding-wrapper {
		position: absolute;
		transition: transform 0.4s ease;
		width: 100%;
		height: 100%;
	}
	
	.sub-wrapper,
	.item-wrapper {
		width: 100%;
		cursor: pointer;

		.sub-wrapper {
			position: absolute;
			left: 100%;
			top: 0;
			display: block;
		}
	}

	.menu-item {
		line-height: 3.125rem;
	}

	.mobile-menu-navigation {
		display: flex;
		justify-content: space-between;
		height: 3.75rem;
		line-height: 3.75rem;
		
		.back-button,
		.close-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 3.75rem;
			width: 3.75rem;

			svg {
				width: 1.5625rem;
				height: 1.5625rem;
				color: #545454
			}
		}
		.close-icon {
			margin-left: auto;
		}
	}
	
	
	.menu-item {
		color: var(--gd-main-text-color);
		display: flex;
		justify-content: space-between;
		height: 3.125rem;
		text-decoration: none;
		padding-left: 1.25rem;
				
		&.active {
			background-color: var(--gd-color-gray);
		}

		&.isSub {
			background-color: #333;
			color: #fff;

			&.active {
				background-color: var(--gd-color-gray-50);
			}
		}
		

		.nav-icon {
			display: flex;
			height: 3.125rem;
			width: 3.125rem;
			justify-content: center;
			align-items: center;
			color: var(--gd-primary-color-border);
			
			svg {
				width: 1.375rem;
				height: 1.375rem;
			}
		}
	}
	
	.login {
		border-radius: 0.625rem;
		padding: 0.625rem;
		margin: 0.625rem;
		margin-top: 0.625rem;
		background-color: var(--gd-color-gray-90);

		.login-button {
			line-height: 1.875rem;
			text-align: center;
			text-decoration: underline;
		} 
		
		.text-spacer {
			line-height: 2.5rem;
			text-align: center;
			font-size: 0.8125rem;
		}
	}
	.my-pages-info {
		height: 100%;
		display: flex;
		flex-direction: column;

		.logout-button {
			line-height: 3.75rem;
			height: 3.75rem;
			width: 100%;
			text-decoration: underline;
			text-align: center;
		}
	}
}
