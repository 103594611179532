
          @import "src/sass/_variables.scss";
        
.SubMenuItem {
	height: 2.1875rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: var(--gd-color-gray);
	border-left: 1px solid var(--gd-color-gray-70);

	&:hover {
		background-color: var(--gd-color-gray-90, #EEEEEE);
	}
	
	.menu-item {
		padding: 0 1.25rem;
		font-style: normal;
		font-weight: normal;
		text-decoration: none;
		font-size: 0.8125rem;
		line-height: 1.4375rem;
		color: var(--gd-main-text-color);
	}
	
	&.active {
		background-color: transparent;
		
		.menu-item {
			color: #fff;
		}
	}
}