
          @import "src/sass/_variables.scss";
        
.WarehouseInfoBubble {
	pointer-events: none;
	fill: transparent;
	
	button {
		all: unset;
		cursor: pointer;

		&.no-cursor {
			cursor: default;
		}
	}

  .Modal .modal-content-container {
    width: 32rem;
  }

	&.external,
	&.inStock,
	&.delayed,
	&.expired {
		pointer-events: auto;
	}

  &.external {
		svg {
    	fill: var(--gd-status-external);
		}
  }

  &.inStock {
    svg {
      fill: var(--gd-status-instock);
    }
  }

	&.delayed {
    svg {
      fill: var(--gd-status-delayed);
    }
  }

  &.expired {
    svg {
      fill: var(--gd-status-expired);
    }
  }
}