.wheelsmart {
	--gd-detail-color: #F5B43D;
	--gd-detail-gradient: #F5B43D;
	--gd-cat-menu-color: var(--gd-cat-menu-color-active);

	.CategoryMenu {
		.background-cover {
			background: rgba(255, 255, 255, 0.2);
		}
	}
}