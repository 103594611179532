html {
	font-size: 100%; // Do not remove
}

body {
	// Fonts
	font-family: var(--gd-font-family);
	font-weight: 400;
	color: var(--gd-main-text-color);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.padding {
	padding: 1.25rem;
}

.margin {
	padding: 1.25rem;
}

// Remove arrows of number input
input[type="number"] {
	-moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
	margin: 0; 
}

// Content
.content-wrapper {
  width: 100%;
  min-height: calc(100vh - 7.5rem);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.no-flex {
    display: block;
  }

  .content {
    width: 100%;
    max-width: 60rem;
    position: relative;
  }
	.spacer-label {
		width: 100%;
		text-align: center;
		font-size: 1.25rem;
		margin-top: 1.25rem;
		margin-bottom: 0.625rem;
		display: block;
	}

	.search-wrapper {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
		min-height: calc(100vh - 12.1875rem - 7.5rem); // Keeps the page from being to small
	}

	.search-form-wrapper {
		.full-size-selector {
			padding-right: 1.25rem;
			
			.SelectPicker {
				padding-right: 0.625rem;
			}
			
			.picker-container {
				max-width: unset;
			}
		}

		.full-size-input {
			.TextInput {
				padding-right: 0.625rem;
			} 
			.input-container .input {
				max-width: unset;
			}
		}
	}
	
  .content-full {
		position: relative;
    max-width: 120rem;
    width: 100%;
		margin: 0 auto; // Centers the content
		min-height: calc(100vh - 19.6875rem); // Keeps the footer on the bottom of the page
		transition: max-width 0.4s ease; 
		
		&.full-screen {
			max-width: 100%;
			
			.search-form-wrapper {
				margin-left: calc((100vw - 120rem) / 2 - 0.625rem);
			}
		}
		
		&.no-sidebar {
			.search-form-wrapper {
				margin-left: calc(((100vw - 120rem) / 2 - 0.625rem) + 15rem);
			}
		}
  }

	/**
	 * Makes sure background color is set in shop when in mobile view
	 */
	 .detail-background {
		.content-full {
			background-color: unset;
		}
	}

	/**
	 * page-content class
	 *
	 * This class is used to set the padding of the page content
	 * Its commonly used within the following layouts:
	 *  - PageLayout
	 *
	 */
  .page-content {
		padding: 0 1.25rem;
		max-width: none;
    width: 100%;
  }

	/**
	 * sidebar-page-content class
	 *
	 * This class is used to set the padding of the content inside the content
	 * Its commonly used within the following layouts:
	 *	- SidebarLayout
	 *
	 */
	.sidebar-page-content {
		padding: 0 1.875rem;
		max-width: none;
    width: 100%;
	}

  .no-background {
    background-color: #fff;
		min-height: calc(100vh - 19.6875rem);
  }
}

// Fix userMenu not above overlay
.ListView {
	.header {
		z-index: 99;
	}
}

.col {
	display: flex;
	flex-direction: column;
}

flex-1 {
	flex: 1;
}

.nowrap {
	flex-wrap: nowrap !important;
}

// rows
.flex {
	display: flex
}

.flex-row {
  display: flex;
  flex-direction: row;

  .flex-col {
    flex: 1;
  }

  &.flex-center {
    justify-content: center;
  }

  .flex-fit {
    padding-right: 1.25rem;
  }
}

.detail-background {
  background: var(--gd-detail-gradient)
}

.search-width {
  max-width: 90rem;
}

// sidebar width or margin to align layout
.sidebar-width {
	width: 15rem;
}

.sidebar-margin {
	margin-left: 15rem;
}

/** Theme */
.detail-background {
	background: var(--gd-detail-gradient)
}

.sidebar-menu {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.sidebar-menu-text-width {
	max-width: 12rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.hideable-with-transition {
	transition: opacity 0.256s ease-out;
}

.option-wrapper {
	color: #fff;
	background: #222;
	border-radius: 0.625rem;
	margin-bottom: 1.25rem;
	transition: 0.2s ease;
	box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.2);
	cursor: pointer;
	user-select: none;
	min-width: 21.875rem;

	td {
		padding: 0.3125rem 0.625rem;
		line-height: 1.25rem;

		&.first {
			padding-top: 0.625rem;
		}

		&.last {
			padding-bottom: 0.625rem;
		}
	}

	.value {
		background-color: rgba(255, 255, 255, 0.1);
		width: 100%;

		&.warning {
			color: var(--gd-color-error)
		}
	}
}

.stopTime-wrapper {
	display: flex;
	align-content: center;

	.ExternalStockFlag {
		margin-right: 0.625rem;
	}
	
	.StopTimeText {
		line-height: 1.25rem;
	}
}

// Layouts
.ProductLayout {
	flex-direction: column;
  justify-content: start;
}


@media only screen and (max-width: $screen-lg-max) {
	html {
		font-size: 75%;
	}
}

// Mobile and responsive design
@media screen and (max-width: $screen-sm-max) {
	.search-form {
		margin: 0 1.875rem;
		margin-bottom: 4.6875rem;
		
		> .row {
			flex-direction: column;
			> div {
				max-width: 100%;
				flex-basis: 100%;

				.form-group {
					margin: 0;
					padding: 0;
					margin-top: 0.3125rem;

					// Full size abit earlier
					.Button .button-input,
					.SelectPicker .picker-container,
					.TextInput .input-container .input {
						max-width: 100%;
					}
				}
			}
		}
	}
	.sidebar-margin {
		margin-left: 0;
	}
}

@media only screen and (max-width: $screen-md-max) {
	html {
		font-size: 100%;
	}
}


.no-user-select {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -ms-touch-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.infoPageContainer {
	margin: 0 1.5625rem;
}
