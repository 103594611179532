
          @import "src/sass/_variables.scss";
        
.MainMenu {
	display: flex;
	height: 100%;
	margin-right: auto;
	
	.menu-item {		
		.menu-link {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			padding: 0 1.5625rem;
			height: 100%;
			font-style: normal;
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.4375rem;
			color: var(--gd-main-text-color);
			text-decoration: none;
		}

		&.active {
			background-color: var(--gd-sub-white);
			

			.nav-name {
				box-shadow: 0 2px 0 var(--gd-border)
			}
		}
	}
}