
          @import "src/sass/_variables.scss";
        
.InputLabel {
	display: block;
	font-size: 0.8125rem;
	line-height: 0.9375rem;
	font-weight: 400;
	font-style: normal;
	margin-bottom: 0.625rem;
	color: var(--gd-main-text-color);

	&.top-margin {
		margin-top: 1.25rem;
	}

	&.no-bottom-margin {
		margin-bottom: 0;
	}
}
