
          @import "src/sass/_variables.scss";
        
.DueInvoicesWindow {
	.intro {
		padding: 0 1.25rem 1.25rem;
	}

	.total {
		padding: 1.25rem;
	}

	.ListView .container > div {
		margin-bottom: 0;
	}

  .Modal {
    .modal-content-container {
      width: 35rem;
			max-width: 95vw;
    }
  }
}