:root {
	// Detail colors you might want to change for your theme
	--gd-detail-color: #5D9ECD;
	--gd-detail-gradient: linear-gradient(90deg, #0073C6 0%, #0BB3E3 100%);
	--gd-detail-sec-color: #FFB735;
	--gd-detail-sec-color-border: #A18B55;

	// Fonts
	--gd-font-family: 'Roboto', sans-serif;
	--gd-font-family-condensed: 'Roboto Condensed', sans-serif;
	--gd-header-font-family: 'Roboto', sans-serif;
	
	// Primary colors
	--gd-primary-color: #ffffff;
	--gd-primary-color-dark: #222222;
	--gd-primary-color-border: #D0D0D0;
	--gd-primary-color-disabled: #999;
	--gd-primary-color-disabled-text: #fff;
	--gd-primary-color-detail: #247DBA;


	// Secondary
	--gd-secondary-color-text: #808080;

	// Event colors
	--gd-color-clean: #fff;
	--gd-color-message: #BEE0F4;
	--gd-color-error: #EE8686;
	--gd-color-success: #CBE7B1;
	--gd-color-warning: #F2D6A1;

	// Event text color
	--gd-text-color-clean: #444444;
	--gd-text-color-message: #256E97;
	--gd-text-color-error: #641717;
	--gd-text-color-success: #4F6831;
	--gd-text-color-warning: #785821;

	// Status Colors
	--gd-status-instock: #7AA74E;
	--gd-status-delayed: #00a8ec;
	--gd-status-external: #e4b315;
	--gd-status-expired: #df5c5c;


	// Card color 
	--gd-card-color: #FFDC86;
	--gd-card-color-border: #A18B55;
	--gd-card-color-text: #745E46;

	// Step colors
	--gd-step-background: #F2F2F2;
	--gd-step-background-first: #F3F9FF;
	--gd-step-background-last:  rgba(241, 104, 61, 0.4);

	// Header
	--gd-header-background: var(--gd-primary-color);
	--gd-header-color: var(--gd-main-text-color);
	--gd-header-sub-color: #666666;

	// ListDividerText
	--gd-list-divider-text-color: var(--gd-primary-color);

	// Menu
	--gd-sub-menu-color: var(--gd-main-text-color);
	--gd-sub-menu-color-active: var(--gd-primary-color);

	// Category Menu
	--gd-cat-menu-color: var(--gd-primary-color);
	--gd-cat-menu-color-active: var(--gd-main-text-color);

	// Gray colors named after its ~ HSL light value
	--gd-color-gray: #F8F8F8; // Gray background
	--gd-color-gray-90: #EEEEEE;
	--gd-color-gray-80: #DDDDDD;
	--gd-color-gray-70: #CCCCCC;
	--gd-color-gray-60: #999999;
	--gd-color-gray-50: #808080;
	--gd-color-gray-40: #666666;
	--gd-color-gray-30: #4D4D4D;
	--gd-color-gray-20: #444444;
	--gd-color-gray-10: #222222;

	// Buttons
	--gd-button-color-dark: #222222;
	--gd-button-color-dark-hover: #3C3C3C;
	--gd-button-color-dark-text: #fff;
	--gd-button-color-light: #fff;
	--gd-button-color-light-hover: #ccc;
	--gd-button-color-warning: #EC5F5F;
	--gd-button-color-warning-darker: #d14f4f;
	--gd-button-color-warning-hover: #db5454;
	--gd-button-color-warning-text: #fff;
	--gd-button-color-secondary: #FFB736;
	--gd-button-color-secondary-darker: #e69807;
	--gd-button-color-secondary-hover: #FDCC6E;
	--gd-button-color-secondary-text: #fff;
	--gd-button-color-accept: #7CD17F;
	--gd-button-color-deny: #EC5F5F;

	// Inputs
	--gd-input-detail-color: #247DBA;
	--gd-input-active-border-color: rgba(0, 0, 0, 0.1);

	// Shadows
	--gd-box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.05);
	--gd-box-shadow-overlay: 0 0.25rem 2.5rem rgba(0, 0, 0, 0.1);

	// Search
	--search-form-spacing: 1.25rem;

	// Licenseplate
	--gd-licenseplate-color: linear-gradient(90deg, #0073C6 0%, #0BB3E3 100%);

	// cart
	--gd-cart-label-color: #A6D0E8;
	--gd-cart-label-text-color: #fff;
	
	// Admin
	--gd-admin-background-color: #4c98cf14;
	--gd-admin-color: #217fba;

	// Logo size
	--gd-logo-login-page-scale: 1;


	/**
	 * 	The new Theme specific variables
	 */
	--gd-font-family: 'Roboto', sans-serif;

	// colors
	--gd-primary: #247DBA;
	--gd-main-text-color: #444444;
	--gd-border: #C0C0C0;
	--gd-dark-prime: #222222;
	--gd-text-placeholder: #CCCCCC;
	--gd-disabled: #CCCCCC;
	--gd-disabled-dark: #AAAAAA;
	--gd-hover: #F2F2F2;
	--gd-white: #FFFFFF;
	--gd-orange: #FFB736;
	--gd-sub-white: #F8F8F8;
	--gd-in-stock: #7AA74E;
	--gd-error-background: #EE8686;
	--gd-error-text: #641717;
	--gd-error-border: #B86C6D;
	--gd-gray: #EEEEEE;
	--gd-primary-light: #689ECC;
	--gd-excl-vat: #DF5C5C;
	--gd-framed-gray: #CCCCCC;
	--gd-wrapper-gray: #EEEEEE;
	--gd-under-description: #AAAAAA;
	--gd-lime: #56C776;
}