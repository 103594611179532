
          @import "src/sass/_variables.scss";
        
.SearchFilter {
	width: 15rem;
	min-width: 15rem;
	z-index: 110;
	background-color: var(--gd-color-gray);
	box-shadow: inset 0 0 0 0.0625rem var(--gd-color-gray-70);
	
	> div {
		position: sticky;
		top: 0;
	}

	@media only screen and (max-width: $screen-md-max) {
		min-width: 0;
		width: 0;
	}
}