
          @import "src/sass/_variables.scss";
        
.StockQuantity {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
	font-weight: 400;

  .ExternalStockFlag {
    position: absolute;
    right: 1.875rem;
  }

  span.external {
    color: var(--gd-status-external);
  }

  span.inStock {
    color: var(--gd-status-instock);
  }

	span.delayed {
    color: var(--gd-status-delayed)
  }

  span.expired {
    color: var(--gd-status-expired);
  }
}
