
          @import "src/sass/_variables.scss";
        
.ArticleText {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.icon {
		width: .875rem;
		height: .875rem;
		margin-right: .5rem;
		margin-left: .25rem;
		fill: var(--gd-main-text-color)
	}
	&.custom-styled {
		font-weight: 700;
		font-size: 1.1em;
	}
	&.hasOriginalArticleId{
		color: var(--gd-input-detail-color)
	}
	.warning-color {
		color: var(--gd-status-expired)
	}
}
