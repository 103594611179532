
          @import "src/sass/_variables.scss";
        
.PasswordChangeForm {
	.Modal .modal-cover .modal-content-container {
		min-width: 20rem;
	}

	.Form {
		padding: 0;
	}

	.or-logout {
		text-align: center;
		margin-top: 0.625rem;
		text-decoration: underline;
		font-size: 0.8125rem;
		cursor: pointer;
		color: #999
	}
}