
          @import "src/sass/_variables.scss";
        
.DetailedFooter {
	display: flex;
	flex-direction: row;
	gap: 1.25rem;
	margin-top: 3.75rem;
	
	.footer-content {
		overflow: hidden;
		background-color: var(--gd-gray);
		height: 100%;
		min-width: 18.75rem;
		border-radius: 0.625rem;
		font-size: 0.8125rem;
		font-weight: 400;
		margin: 0;

		.row {
			gap: 3.75rem
		}
		
		.link-list {
			display: flex;
			flex-direction: column;

			nav {
				flex-direction: column;
				display: flex;
				gap: 0.625rem;

				a {
					all: unset;
					cursor: pointer;
					line-height: 0.8125rem;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
			
			.SocialLinks {
				margin-top: auto;
			}
		}
	}

	.footer-map {
		overflow: hidden;
		background-color: var(--gd-gray);
		border-radius: 0.625rem;
		width: 23.125rem;
		min-height: 14.375rem;
	}

	.company-details {
		max-width: 12.5rem;
		line-height: 1.25rem;

		> div {
			margin-bottom: 0.625rem;
			
			&:last-child {
				margin-bottom: unset;
			}
		}
	}

	// Media Queries
	@media (max-width: 1000px) {
		flex-direction: column;
		gap: 1.25rem;
		
		.footer-content {
			min-width: unset;
			width: 100%;
		
			.row {
				gap: 1.25rem;
			}

			.link-list {

				.SocialLinks {
					margin-top: 1.5625rem;
					margin-bottom: 0.3125rem;
				}
			}
		}

		.footer-map {
			width: 100%;
		}
	}
}