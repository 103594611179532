
          @import "src/sass/_variables.scss";
        
.QuickSearch {
	background-color: var(--gd-sub-white);
	justify-content: center;
	position: absolute;
	display: flex;
	top: 7.5rem;
	width: 100%;
	z-index: 1000;
	left: 0;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	.search-wrapper {
		width: 100%;
		max-width: 50rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		.search-results {
			width: 100%;
			max-width: 50rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			
			.no-results {
				margin: 20px 0;
			}

			.ScrollBox {
				width: 100%;
			}
			
			.Button {
				margin: 1.25rem 0;
				display: flex;
				width: 100%;

				> div {
					display: flex;
					width: 100%;

					button {
						max-width: unset;
					}
				}
			}
			
			.SearchResultProductCardSmall {
				width: 100%;

				.ProductCardSmall {
					padding: 20px 0 ;
				}
			}
		}
	}
}
