
          @import "src/sass/_variables.scss";
        
@import "../sass/themes";
@import "../sass/normalize";
@import "../sass/baseVariables";
@import "../sass/baseStyle";
@import "../sass/typography";
@import "../sass/flexboxgrid.scss";
@import "../sass/animate.scss";
@import "../sass/simpleMessage.scss";

// Modal overlay
.App {
	> .main-overlay {
		&::before {
			content: "";
			display: block;
			background-color: rgba(0, 0, 0, 0);
			position: fixed;
			left: 0;
			pointer-events: none;
			user-select: none;
			top: 0;
			height: 100vh;
			width: 100vw;
			z-index: 999;
			transition: 0.2s ease
		}

		&.overlay::before {
			background-color: rgba(0, 0, 0, 0.2);
		} 
	}
}