
          @import "src/sass/_variables.scss";
        
.ScrollBox {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.5s ease;
  border-radius: 0.3125rem;

  &.top {
    box-shadow: inset 0px -10px 10px -10px rgba(0, 0, 0, 0.5);
  }

  &.center {
    box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 0.5), inset 0px -10px 10px -10px rgba(0, 0, 0, 0.35);
    ;
  }

  &.bottom {
    box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
}