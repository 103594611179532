
          @import "src/sass/_variables.scss";
        
.MenuItem {
	position: relative;
  display: flex;
  flex-direction: column;
	justify-content: space-around;
  align-items: center;  
  color: #fff;
  z-index: 10;
	transition: .2s ease;
	user-select: none;

	.img-wrapper {
		max-width: 6.25rem;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			transition: .2s ease;
			position: relative;
			width: 6.25rem;
			height: auto;
		}
	}

  .label {
		transition: .2s ease;
    color: var(--gd-cat-menu-color);
		line-height: 1.875rem;
		font-size: 0.8125rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
  }

	&.active {
		.img-wrapper {
			img {
				width: 7.5rem;
			}
		}

		.label {
    	color: var(--gd-cat-menu-color-active);
 	 }
	}
}