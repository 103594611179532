
          @import "src/sass/_variables.scss";
        
.MiniCartCard {
  position: absolute;
  top: 100%;
  padding-top: 0.9375rem;
  right: 0;
  width: 25rem;
  min-height: 6.25rem;
  background-color: var(--gd-primary-color);
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0 0.9375rem;
  z-index: 99999;

  .gray-bkg {
    box-sizing: border-box;
    background-color: var(--gd-color-gray);
    width: 100%;
    padding: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.4375rem;

    .total-sum {
      flex: 1;
    }

    .button {
      width: 100%;
      max-width: unset;
    }

    .cart-info {
      margin-bottom: 1.25rem;

      .article-prices {
        font-weight: 600;
        text-align: right;
      }

      .article-amount {
        flex: 0.5;
      }
    }
  }

  .Button {
    .button-input {
      max-width: unset;
    }
  }

  .chosen-text {
    color: var(--gd-color-gray-50);
    font-size: 0.9375rem;
    line-height: 1.875rem;
    text-align: center;
  }

  .product-list {
    padding: 0.3125rem;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: normal;

    .PackageRow {
      background-color: var(--gd-color-gray-90);
      border-radius: 0.3125rem;
      position: relative;
      overflow: hidden;


      .side-label {
        position: absolute;
        background-color: var(--gd-cart-label-color);
        color: var(--gd-cart-label-text-color);
        width: 1.5625rem;
        height: 5rem;
        display: flex;
        font-size: 0.875rem;
        justify-content: center;
        align-items: center;

        >div {
          transform: rotate(-90deg);
          text-transform: uppercase;
        }
      }

      .cart-item {
        padding-left: 1.875rem;
      }

      .ListViewLicensePlateInput {
        max-width: 5.3125rem;

        .input-container .input {
          max-width: 3.75rem;
          min-width: 3.75rem;
          font-size: 0.9375rem;
          font-family: var(--gd-font-family, sans-serif);
          font-weight: 800;
        }
      }
    }

    .cart-item {
      padding: 0.625rem;
      height: 2.5rem;
      display: flex;
      align-items: center;

      .ListViewButton {
        width: 5.3125rem;
      }

      .product-item-content {
        align-items: center;
        display: flex;
        height: 1.5625rem;
        flex: 1;
        flex-direction: row;
        text-decoration: none;
        color: var(--gd-main-text-color);

        .item-id {
          width: 3.125rem;
          padding-right: 0.625rem;
        }

        .item-name {
          flex: 1;
          width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.625rem;

					span {
						text-overflow: ellipsis;
						overflow: hidden;
						display: block;
					}
        }
      }

      .item-button {
        height: 1.5625rem;
        padding: 0 0.9375rem;
        font-size: 0.9375rem;
        color: #fff;
        border: none;
        border-radius: 0.3125rem;
        background-color: var(--gd-color-gray-60);
      }
    }
  }

	@media screen and (max-width: $screen-sm-min) {
		width: 100vw;
		height: calc(100vh - 3.75rem);
		display: flex;
    flex-direction: column;
		
		.gray-bkg { 
			margin-top: auto

		}
	}
}