
          @import "src/sass/_variables.scss";
        
.card-default {
	background-color: #fff;
	border-radius: 0.625rem;
	border: 1px solid var(--gd-border);
	padding: 1.25rem;
	margin-bottom: 1.25rem;
	max-width: 72.5rem;
	overflow: hidden;

	&.gray-card {
		background-color: var(--gd-gray);
		border: 0;
	}

	&.no-padding {
		padding: 0;
	}

	&.margin {
		margin: 1.25rem;
	}

	&.no-margin {
		margin: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.Card {
	@extend .card-default;

	> .MainLabel {
		margin-top: 0;
	}
}

