
          @import "src/sass/_variables.scss";
        
.ShopLayout {
	.shop-content {
		display: flex;
		flex-direction: row;
		position: relative;
    max-width: 120rem;
    width: 100%;
		margin: 0 auto; // Centers the content
		min-height: calc(100vh - 19.6875rem); // Keeps the footer on the bottom of the page
		transition: max-width 0.4s ease; 	
	}

	section {
		width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    min-height: inherit;
	}

	.category-wrapper {
		width: 100%;
		max-width: 120rem;
		margin: 0 auto;
		transition: max-width 0.4s ease;
	}
}

.full-screen {
	.ShopLayout {
		.category-wrapper {
			max-width: 100%;
		}

		.shop-content {
			max-width: 100%;
		}
	}
}