
          @import "src/sass/_variables.scss";
        
.ContactInformationButton {
	all: unset;
	height: 2.1875rem;
	min-width: 3.125rem;
	box-sizing: border-box;
	padding: 0 0.625rem 0 1.25rem;
	background-color: var(--gd-color-gray);
	border-left: 0.0625rem solid var(--gd-color-gray-70);
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	pointer-events: all;

	&:hover {
		background-color: var(--gd-color-gray-90);
	}
	
	span:last-child {
		margin-left: 1.25rem;
	}
}